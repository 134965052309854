import * as React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import ImageHeader from '../components/ImageHeader'
import {graphql} from 'gatsby'
import IndexFilter from '../components/IndexFilter'
import Layout from "../components/Layout"
import Seo from '../components/Seo'
import '../styles/index.scss'

const TopicIndexByType = ({data}) => {
    let allTopicNames = [...new Map(data.allWpTopic.nodes.map(item => [item.slug, item])).values()]
    //get all topics with a parent of null and map them
    //while mapping parent topics, find and map all children topics with matching parent dbid
    return (
        <Layout>
            <Seo
                title={`${data.wpResourceType.name}  by Topic`}
                description={`Index for ${data.wpResourceType.name}  by Topic`}
            />
            <ImageHeader title={`${data.wpResourceType.name}  by Topic`}
                         image={data.wp.resourcesGeneralSettings.resourceLibraryHeader.headerImage.sourceUrl}/>
            <Breadcrumbs title="Topic Index" isResource={true} />
            <IndexFilter taxonomy="topics" />
            <section className="index container">
                {allTopicNames.filter((term) => term.parentDatabaseId === null).filter((term) => term.count > 0).map((term, index) =>
                    <div key={index} className="index__group">
                        <div className="index__group__title">
                            <div className="index__group__title__first">
                                <h6><a href={`/resources/topics/${term.slug}/${data.wpResourceType.slug}`}>{term.name}</a></h6>
                            </div>
                        </div>
                        {term.wpChildren.nodes &&
                        <ul className="index__group__children">
                            {term.wpChildren.nodes.filter((child) => child.count > 0).map((child, index) =>
                                <li key={index} className="index__group__children__child">
                                    <a href={`/resources/topics/${child.slug}/${data.wpResourceType.slug}`}>
                                        <div>
                                            {child.name}
                                        </div>
                                    </a>
                                </li>
                            )}
                        </ul>
                        }
                    </div>
                )}
            </section>
        </Layout>
    )
}

export default TopicIndexByType

export const query = graphql `
    query ($type: String!) {
        allWpTopic(
            filter: {mereResources: {nodes: {elemMatch: {resourceTypes: {nodes: {elemMatch: {slug: {eq: $type}}}}}}}}
        ) {
            nodes {
                name
                id
                slug
                parentDatabaseId
                count
                wpChildren {
                    nodes {
                        name
                        slug
                        count
                        mereResources {
                            nodes {
                                title
                                slug
                                resourceTypes {
                                    nodes {
                                        name
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        wpResourceType(slug: {eq: $type}) {
            name
            slug
        }
        wp {
            resourcesGeneralSettings {
                resourceLibraryHeader {
                    headerImage {
                        sourceUrl
                    }
                }
            }
        }
    }
`